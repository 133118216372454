.modal {
  background: var(--redo-colors-background-bg-primary);
  border-radius: var(--redo-corner-small-radius);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  flex-direction: column;
  max-height: 90%;
  overflow: auto;

  transition:
    height var(--redo-collapse-duration),
    width var(--redo-collapse-duration),
    min-width var(--redo-collapse-duration),
    max-width var(--redo-collapse-duration);

  min-width: min(600px, 90%);
  max-width: 700px;
  width: 60%;

  &.small {
    min-width: min(400px, 90%);
    max-width: 500px;
    width: 50%;
  }

  &.large {
    min-width: min(800px, 90%);
    max-width: 900px;
    width: 80%;
  }

  &.extra-large {
    min-width: min(1000px, 90%);
    max-width: 1400px;
    width: 80%;
  }

  &.centered {
    align-items: center;
    text-align: center;
  }
}

.header {
  padding: calc(var(--redo-spacing) * 3);
  display: flex;
  flex-direction: column;
  gap: calc(var(--redo-spacing) * 2);
  &.border {
    border-bottom: 1px solid var(--redo-colors-border-border-primary);
    margin-bottom: calc(var(--redo-spacing) * 3);
  }
  &.inline {
    align-items: center;
    flex-direction: row;
  }
  &.center {
    flex-wrap: wrap;
    align-items: center;
  }

  .title-icon {
    display: flex;
    align-items: flex-start;
  }

  .close-button {
    position: absolute;
    top: 24px;
    right: 24px;
  }
}

.main {
  padding: 0px calc(var(--redo-spacing) * 3);

  &.scrollable {
    overflow-y: auto;
  }
}

.footer {
  padding: calc(var(--redo-spacing) * 3);
  display: flex;
  justify-content: space-between;

  &.border {
    border-top: 1px solid var(--redo-colors-border-border-primary);
    margin-top: calc(var(--redo-spacing) * 3);
  }

  &.fill {
    width: 100%;
  }

  > .footer-action {
    flex-grow: 1;
    display: flex;
    align-items: center;
  }

  > .footer-buttons {
    display: flex;
    gap: calc(var(--redo-spacing) * 2);

    &.fill {
      width: 100%;

      .button {
        flex-grow: 1;
      }
      .disabled-wrapper {
        flex: 1;
        flex-grow: 1;
      }
    }

    &.tight {
      width: 100%;
      justify-content: flex-end;
    }
  }
}

.icon {
  > * {
    height: var(--redo-icon-large-size);
    width: var(--redo-icon-large-size);
  }
  padding: 12px;
  display: flex;
  &.error,
  &.warn,
  &.success,
  &.info,
  &.neutral {
    border-radius: 9999px;
  }
  &.none {
    border-radius: calc(var(--redo-spacing-unit) * 2.5);
    border: 1px solid var(--redo-colors-border-border-primary);
  }
  &.error {
    background-color: var(--redo-colors-background-bg-error-secondary);
    > * {
      color: var(--redo-colors-foreground-fg-error-primary);
    }
  }
  &.warn {
    background-color: var(--redo-colors-background-bg-warning-secondary);
    > * {
      color: var(--redo-colors-foreground-fg-warning-primary);
    }
  }
  &.success {
    background-color: var(--redo-colors-background-bg-success-secondary);
    > * {
      color: var(--redo-colors-foreground-fg-success-primary);
    }
  }
  &.info {
    background-color: var(--redo-colors-background-bg-brand-secondary);
    > * {
      color: var(--redo-colors-foreground-fg-brand-primary);
    }
  }
  &.neutral {
    background-color: var(--redo-colors-background-bg-quaternary);
    > * {
      color: var(
        --redo-component-colors-components-icons-featured-icons-light-featured-icon-light-fg-gray
      );
    }
  }
}
